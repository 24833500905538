<template>
    <div class="position-relative">
        <!-- <div :class="`${navbarAbsolute == 1 ? 'position-absolute' : 'position-relative'} w-100`" style="z-index:999px"> -->
            <Navbar :transparent="navbarTransparent == 1" :absolute="navbarAbsolute" />
        <!-- </div>  -->
        <b-overlay
          id="overlay-background"
          :show="loading.isLoading"
          variant="light"
          opacity="0.8"
          blur="2px"
          rounded="sm"
          class="ql-editor p-0 m-0"
        > 
            <slot />

            <template #overlay>
                    <img src="../../public/logo-color.png" alt="Logo" />
            </template>

        </b-overlay>
        <Footer />
        <FABHelp />
    </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
import FABHelp from "@/components/FAB.vue";

import {BOverlay} from 'bootstrap-vue'
import store from '@/store'

import Cookies from 'universal-cookie'

const cookies = new Cookies()

export default { 
    props: {
        navbarAbsolute: Boolean,
        navbarTransparent : Boolean
    },
    components: {
        BOverlay,
        Navbar,
        Footer,
        FABHelp
    },
    computed: {
        loading() {
            return store.state.loading;
        },
    },
    mounted(){
        if(!cookies.get('lang')){
            cookies.set('lang', 'EN', { path: '/' })
        } else {
            this.$store.dispatch('locales/changeLanguage', cookies.get('lang'))
        }
    }
};
</script> 