<template>
    <Layout navbarTransparent>
        <span v-for="data in data_list" :key="data.event_id">
            <div style="background: linear-gradient(93.63deg, #0B5087 20.76%, #0D5D9C 49.78%, #0F6AB2 66.2%, #0F6BB4 98.65%);" class="p-4">
                <div class="container pb-5">
                    <h1 class="display-4 font-weight-bolder text-white" style="max-width:1000px">{{data.event_title}}</h1>
                    <a :href="data.event_redirect" style="" class="" v-if="data.event_redirect">
                        <div
                            class="mt-2 py-1 px-2 rounded-pill shadow fab-help"
                            style="max-width: fit-content;"
                            >
                            Register For Free
                        </div>
                    </a>
                </div>
            </div>

            <div class="py-5">
                <h1 class="text-primary-darker font-weight-bolder text-center mt-4">
                    {{data.event_subtitle}}
                </h1>
                <h4 class="text-primary-darker my-3 text-center">
                    About Events
                </h4>

                <div class="container">
                    <div v-html="data.event_desc" class="ql-editor"></div>
                </div>
            </div>

            <div class="container d-flex mt-4">
                <div class="bg-white rounded-lg mx-auto p-1 position-relative shadow w-100" style="max-width:1100px;margin-bottom:-10em;z-index:999">
                    <div class="row">
                        <div class="col-lg-6">
                            <img src="@/static/assets/event/item4.png" class="w-100" style="object-fit:cover" />
                        </div>
                        <div class="col-lg-6 d-flex flex-column">
                            <h1 class="text-primary-darker font-weight-bolder mt-auto">
                                {{data.event_subtitle}}
                            </h1>
                            <div class="d-flex flex-column mt-1" style="gap:10px"> 
                                <h5 class="mb-0 d-flex align-items-center">
                                    <i class="bx bx-calendar mr-1 text-primary-darker"  style="font-size:20px"></i>
                                    {{moment(data.event_startdate).format('Do MMMM YYYY')}} - {{moment(data.event_enddate).format('Do MMMM YYYY')}}
                                </h5>  
                                <h5 class="mb-0 d-flex align-items-center">
                                    <i class="bx bxs-edit-location mr-1 text-primary-darker" style="font-size:20px"></i>
                                    {{data.event_location}}
                                </h5> 
                                <h5 class="mb-0 d-flex align-items-center">
                                    <i class="bx bx-group mr-1 text-primary-darker"  style="font-size:20px"></i>
                                    Kuota : {{data.event_quota}} participant
                                </h5> 
                                <h5 class="mb-0 d-flex align-items-center" v-if="data.event_price">
                                    <i class="bx bx-wallet mr-1 text-primary-darker"  style="font-size:20px"></i>
                                    IDR {{new Intl.NumberFormat({ maximumSignificantDigits: 3 }).format(data.event_price)}}
                                </h5> 
                            </div>
                            <div class="my-auto">
                                <a :href="data.event_redirect" style="" class="">
                                    <div
                                    class="px-1 rounded-pill shadow fab-help"
                                    style="padding:.5em 0;max-width: fit-content;"
                                    >
                                    Register For Free
                                    </div>
                                </a>
                            </div>
                            <span class="d-flex align-items-center" style="padding:.5em 0;gap:5px;font-size:16px;border-top:2px solid #EF6D22;border-bottom:2px solid #EF6D22">
                                <span class="mr-1 ml-2">Share with</span>
                                <div class="d-flex text-primary-main" style="gap:15px;font-size:25px">
                                    <i class="bx bxl-whatsapp"></i>
                                    <i class="bx bxl-linkedin"></i>
                                    <i class="bx bxl-telegram"></i>
                                    <i class="bx bxl-facebook"></i>
                                </div>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <img src="@/static/assets/event/item7.png" class="w-100" style="object-fit:cover;object-position:top;max-height:300px" />
        </span>
    </Layout>
</template>

<script>

import Layout from '@/layouts/main.vue'
import store from '@/store'
import moment from 'moment'

export default {
    components: {
        Layout
    },
    mounted(){
        this.getNextDatas()
    },
    data(){
        return {
            moment,
            data_list: []
        }
    },
    computed: {
        id: function () {
            return this.$route.params.id;
        }
    },
    methods:{
        async getNextDatas(){
            let temp = await store.dispatch(`events/getDataList`, { 
                id: this.id
            }) 
            this.data_list = this.data_list ? this.data_list.concat(temp.data) : temp.data 
            this.$forceUpdate() 
        }, 
    }
}
</script>